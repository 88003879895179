<template>
    <div>
        <div class="set-container">
            <div class="tab-box">
                <el-input placeholder="请输入收件人手机号或快递单号" v-model="searchValue" style="width: 500px" class="input-box">
                    <el-select v-model="searchMethod" slot="prepend" placeholder="请选择" @change="chooseSearchMethod" class="select-box">
                        <el-option label="按收件人手机号搜索" :value="1"></el-option>
                        <el-option label="按快递单号搜索" :value="2"></el-option>
                    </el-select>
                    <el-button slot="append" @click="searchBtn">搜索</el-button>
                </el-input>
            </div>
            <el-tabs v-model="status" @tab-click="handleClick" type="card">
                <el-tab-pane label="全部订单" name="-1"></el-tab-pane>
                <el-tab-pane label="待寄订单" name="0"></el-tab-pane>
                <el-tab-pane label="已寄订单" name="1"></el-tab-pane>
                <el-tab-pane label="关闭订单" name="2"></el-tab-pane>
            </el-tabs>

            <el-table :data="infoList" class="customTable" style="width: 100%; flex: 1" height="1%" ref="multipleTable">
                <el-table-column prop="send_city" label="始发地" align="center"></el-table-column>
                <el-table-column prop="accept_username" label="收件人" align="center"></el-table-column>
                <el-table-column prop="accept_city" label="目的地" align="center"></el-table-column>
                <el-table-column prop="accept_mobile" label="收件人手机号" align="center"></el-table-column>
                <el-table-column prop="mail_no" label="快递单号" align="center"></el-table-column>
                <el-table-column prop="create_time" label="下单时间" align="center"></el-table-column>

                <el-table-column label="状态" align="center">
                    <template slot-scope="scope">
                        {{scope.row.status === 0 ? '待寄' : scope.row.status === 1 ? '已寄' : scope.row.status === 2 ? '删除' : '送达'}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="200">
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" @click="seeBtn(scope.row)">查看</el-link>
                        <template v-if="scope.row.status === 0 || scope.row.status === 1">
                            <el-link type="warning" :underline="false" @click="editBtn(scope.row)">编辑</el-link>
                        </template>
                        <template v-if="scope.row.status === 0">
                            <el-link type="success" :underline="false" @click="sendBtn(scope.row)">发货</el-link>
                        </template>
                        <el-link type="danger" :underline="false" @click="delBtn(scope.row)">删除</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="pages-center"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           layout="prev, pager, next, jumper"
                           :total="listPages.total"
                           @current-change="pageCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import { mail_manageIndex, mail_manageChange, mail_manageDel } from '@/config/apis.js'
    export default {
        name: "Sending",
        data() {
            return {
                status: '-1', // tabs值
                searchValue: '',
                searchMethod: 1,
                infoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            // 选择搜索方式
            chooseSearchMethod() {
                this.listPages.currentPageNum = 1
                this.searchValue = ''
                this.getList()
            },
            // 搜索
            searchBtn() {
                if (!this.searchValue) {
                    return this.$message.warning('请输入收件人手机号或快递单号')
                }
                this.listPages.currentPageNum = 1
                this.getList()
            },
            // 切换tab
            handleClick() {
                this.listPages.currentPageNum = 1
                this.getList()
            },
            // 获取列表
            getList() {
                let params = {
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum,
                }
                if (this.status !== '-1') {
                    params.status = this.status
                }
                if (this.searchMethod) {
                    if (this.searchMethod === 1 && this.searchValue) {
                        params.mobile = this.searchValue
                    }
                    if (this.searchMethod === 2 && this.searchValue) {
                        params.mail_no = this.searchValue
                    }
                }
                mail_manageIndex(params).then(res => {
                    console.log('res', res);
                    this.infoList = res.data.list
                    this.listPages.total = res.data.total
                    if (this.listPages.total !== 0 && this.infoList.length === 0) {
                        this.listPages.currentPageNum--;
                        this.getList();
                    }
                }).catch(err => {})
            },
            // 切换分页
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            // 查看
            seeBtn(val) {
                this.$router.push({
                    path: '/school/expressManage/sending/see',
                    query: {
                        id: val.id
                    }
                })
            },
            // 编辑
            editBtn(val) {
                this.$router.push({
                    path: '/school/expressManage/sending/edit',
                    query: {
                        id: val.id
                    }
                })
            },
            // 发货
            sendBtn(val) {
                this.$confirm('确定要发货？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    let formData = new FormData()
                    formData.append('id', val.id)
                    formData.append('status', '1')
                    mail_manageChange(formData).then(res => {
                        this.$message.success(res.msg)
                        this.getList()
                    }).catch(err => {})
                }).catch(() => {
                    this.$message.info('已取消发货');
                })
            },
            // 删除
            delBtn(val) {
                this.$confirm('删除后将无法恢复，确定要删除？', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(() => {
                    if (Number(this.status) === 2) {
                        mail_manageDel(val.id).then(res => {
                            this.$message.success(res.msg)
                            this.getList()
                        }).catch(err => {})
                    } else {
                        let formData = new FormData()
                        formData.append('id', val.id)
                        formData.append('status', '2')
                        mail_manageChange(formData).then(res => {
                            this.$message.success(res.msg)
                            this.getList()
                        }).catch(err => {})
                    }
                }).catch(() => {
                    this.$message.info('已取消删除');
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .set-container {
        margin: 20px;
        background: #fff;
        height: calc(100% - 40px);
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        box-sizing: border-box;
    }
    .tab-box {
        margin-bottom: 20px;
    }
    ::v-deep .input-box {
        .el-input-group__prepend {
            background: #fff;
        }
        .select-box {
            width: 180px;
        }
        .el-input-group__append {
            color: #fff;
            background: #409eff;
        }
    }
</style>